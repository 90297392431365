import api from './api';

class CampoService {
  
  getItems(id, cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/layouts/${id}/campos${cadenaBusqueda}`);
  }

  newItem(id, params) {
    return api.post(`/api/layouts/${id}/campos`, params)
  }

  editItem(id, params) {
    return api.put(`/api/layouts/${id}/campos/${params.id}`, params)
  }
}

export default new CampoService();