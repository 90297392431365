import api from './api';

class VariableService {
  
  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/variables${cadenaBusqueda}`);
  }

  getItem(layout_id, id) {
    return api.get(`/api/layouts/${layout_id}/variables/${id}`);
  }

  newItem(layout_id, params) {
    return api.post(`/api/layouts/${layout_id}/variables`, params)
  }

  editItem(layout_id, params) {
    return api.put(`/api/layouts/${layout_id}/variables/${params.id}`, params)
  }
}

export default new VariableService();