<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Edición de Paso {{ paso.nombre }} del Proceso de Calidad
        {{ proceso.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="paso.nombre"
                label="Nombre del Paso"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="paso.descripcion"
                label="Descripción"
                :counter="1000"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingClasesPaso"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingClasesPaso"
                v-model="paso.clase_paso_id"
                :items="clasesPaso"
                :item-text="'valor_texto'"
                :item-value="'id'"
                attach
                label="Clase de Paso"
                :rules="required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <div
                v-if="loadingCampos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingCampos"
                v-model="paso.campo_id"
                :items="campos"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Campo"
                :rules="required"
              ></v-select>
            </v-col>
          </v-row>
          <v-row style="display:none">
            <v-col cols="12" md="6">
              <v-slider
                v-model="peso"
                label="Peso"
                max="100"
                min="0"
                hide-details
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="paso.peso"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    readonly
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <v-card
                elevation="2"
                tile
                style="
                  text-align: center;
                  padding: 20px 0 20px 0;
                  background-color: #f9f9f9;
                "
              >
                <nodo :elemento="expresion" :reglas="reglas"></nodo>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div
                v-if="loadingPasos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingPasos && clasePaso != 'Inicio'"
                v-model="pasosPredecesores"
                :items="pasos"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Pasos predecesores"
                multiple
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-card
                elevation="2"
                tile
                style="
                  text-align: center;
                  padding: 20px 0 20px 0;
                  background-color: #f9f9f9;
                "
              >
                <h5>Cumple</h5>
                <v-divider></v-divider>
                <v-card-text>
                  <div
                    v-if="loadingPasos"
                    class="spinner-border"
                    role="status"
                  ></div>
                  <v-select
                    v-if="!loadingPasos && clasePaso != 'Fin'"
                    v-model="cumplePasosSiguientes"
                    :items="pasos"
                    :item-text="'nombre'"
                    :item-value="'id'"
                    attach
                    label="Pasos siguientes"
                    multiple
                  ></v-select>
                  <v-text-field
                    v-model="paso.cumpleObservacion"
                    label="Observación"
                    :counter="100"
                    hide-details="auto"
                  ></v-text-field>
                  <v-text-field
                    v-model="paso.cumpleObservacionComplementaria"
                    label="Observación Complementaria"
                    :counter="100"
                    hide-details="auto"
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" md="6" style="display:none">
                      <div
                        v-if="loadingVariables"
                        class="spinner-border"
                        role="status"
                      ></div>
                      <v-select
                        v-if="!loadingVariables"
                        v-model="paso.cumple_variable_destino_id"
                        :items="variables"
                        :item-text="'nombre'"
                        :item-value="'id'"
                        attach
                        label="Asignar Valor"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" style="display:none">
                      <div
                        v-if="loadingVariables"
                        class="spinner-border"
                        role="status"
                      ></div>
                      <v-select
                        v-if="!loadingVariables"
                        v-model="paso.cumple_variable_origen_id"
                        :items="variables"
                        :item-text="'nombre'"
                        :item-value="'id'"
                        attach
                        label="Valor"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card
                elevation="2"
                tile
                style="
                  text-align: center;
                  padding: 20px 0 20px 0;
                  background-color: #f9f9f9;
                "
              >
                <h5>No Cumple</h5>
                <v-divider></v-divider>
                <v-card-text>
                  <div
                    v-if="loadingPasos"
                    class="spinner-border"
                    role="status"
                  ></div>
                  <v-select
                    v-if="!loadingPasos && clasePaso != 'Fin'"
                    v-model="noCumplePasosSiguientes"
                    :items="pasos"
                    :item-text="'nombre'"
                    :item-value="'id'"
                    attach
                    label="Pasos siguientes"
                    multiple
                  ></v-select>
                  <v-text-field
                    v-model="paso.noCumpleObservacion"
                    label="Observación"
                    :counter="100"
                    hide-details="auto"
                  ></v-text-field>
                  <v-text-field
                    v-model="paso.noCumpleObservacionComplementaria"
                    label="Observación Complementaria"
                    :counter="100"
                    hide-details="auto"
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" md="6" style="display:none">
                      <div
                        v-if="loadingVariables"
                        class="spinner-border"
                        role="status"
                      ></div>
                      <v-select
                        v-if="!loadingVariables"
                        v-model="paso.no_cumple_variable_destino_id"
                        :items="variables"
                        :item-text="'nombre'"
                        :item-value="'id'"
                        attach
                        label="Asignar Valor"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" style="display:none">
                      <div
                        v-if="loadingVariables"
                        class="spinner-border"
                        role="status"
                      ></div>
                      <v-select
                        v-if="!loadingVariables"
                        v-model="paso.no_cumple_variable_origen_id"
                        :items="variables"
                        :item-text="'nombre'"
                        :item-value="'id'"
                        attach
                        label="Valor"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-switch v-model="paso.activo" :label="`Estado`"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <pre>{{ expresion }}</pre>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PasoProcesoCalidadService from "@/services/pasoProcesoCalidad.service";
import validationField from "@/util/validationField";
import CamposService from "@/services/campo.service";
import ReglaService from "@/services/regla.service";
import VariableService from "@/services/variable.service";
import ListaService from "@/services/lista.service";
import NodoOpl from "./NodoOpl.js";
import { mapState } from "vuex";

export default {
  name: "Calidad",

  data() {
    return {
      paso: {
        nombre: undefined,
        descripcion: undefined,
        campo_id: undefined,
        clase_paso_id: undefined,
        peso: 0,
        expresion: undefined,
        pasosPredecesores: [],
        cumplePasosSiguientes: [],
        cumpleObservacion: "",
        cumpleObservacionComplementaria: "",
        cumple_variable_origen_id: undefined,
        cumple_variable_destino_id: undefined,
        noCumplePasosSiguientes: [],
        noCumpleObservacion: "",
        noCumpleObservacionComplementaria: "",
        no_cumple_variable_origen_id: undefined,
        no_cumple_variable_destino_id: undefined,
        activo: true,
      },
      expresion: undefined,
      pasosPredecesores: [],
      cumplePasosSiguientes: [],
      noCumplePasosSiguientes: [],
      proceso: {
        nombre: undefined,
      },
      peso: 0,
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      reglas: [],
      pasos: [],
      variables: [],
      clasesPaso: [],
      campos: [],
      loading: false,
      loadingPasos: false,
      loadingReglas: false,
      loadingClasesPaso: false,
      loadingCampos: false,
      loadingVariables: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  components: {
    nodo: () => import("./Nodo.vue"),
  },
  computed: {
    ...mapState(["permissions"]),
    clasePaso: function () {
      return (this.clasesPaso.length && this.paso.clase_paso_id)
        ? this.clasesPaso.find((x) => x.id == this.paso.clase_paso_id)
            .valor_texto
        : "";
    },
  },
  watch: {
    peso(newVal) {
      this.paso.peso = newVal / 100;
    },
  },
  mounted() {
    this.expresion = new NodoOpl();

    if (!this.permissions.includes("Definición de proceso de calidad"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_proceso_calidad")) {
      this.proceso = JSON.parse(
        localStorage.getItem("datos_proceso_calidad")
      );
      this.fetchCampos("");
      this.fetchVariables("");
    }
    if (localStorage.getItem("datos_paso_proceso_calidad")) {
      this.paso = JSON.parse(
        localStorage.getItem("datos_paso_proceso_calidad")
      );
      this.expresion = JSON.parse(this.paso.expresion);
      this.pasosPredecesores = JSON.parse(this.paso.pasosPredecesores);
      this.cumplePasosSiguientes = JSON.parse(this.paso.cumplePasosSiguientes);
      this.noCumplePasosSiguientes = JSON.parse(
        this.paso.noCumplePasosSiguientes
      );
    }

    this.fetchClasesPaso("");
    this.fetchPasos("");
    this.fetchReglas("");
  },
  methods: {
    save() {
      this.loadingSave = true;
      this.paso.proceso_calidad_id = this.proceso.id;
      this.paso.expresion = JSON.stringify(this.expresion);
      this.paso.pasosPredecesores = "[]";
      this.paso.cumplePasosSiguientes = "[]";
      this.paso.noCumplePasosSiguientes = "[]";
      if (this.clasePaso != "Inicio") // && this.pasosPredecesores.length
        this.paso.pasosPredecesores = JSON.stringify(this.pasosPredecesores);
      if (this.clasePaso != "Fin") {
        this.paso.cumplePasosSiguientes = JSON.stringify(
          this.cumplePasosSiguientes
        );
        this.paso.noCumplePasosSiguientes = JSON.stringify(
          this.noCumplePasosSiguientes
        );
      }
      if (this.paso.hasOwnProperty("id")) {
        PasoProcesoCalidadService.editItem(this.proceso.id, this.paso).then(
          (response) => {
            this.$router.push({ path: `/calidad/procesos-calidad/pasos` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        PasoProcesoCalidadService.newItem(this.proceso.id, this.paso).then(
          (response) => {
            this.$router.push({ path: `/calidad/procesos-calidad/pasos` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchReglas(cadenaBusqueda) {
      this.loadingReglas = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
        
      ReglaService.getItems(cadenaBusqueda).then(
        (response) => {
          this.reglas = response.data;
          this.reglas = this.reglas.map(function f(x) {
            return {
              id: x.id,
              nombre: x.nombre,
              expresion: x.expresion,
            };
          });
          this.loadingReglas = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingReglas = false;
        }
      );
    },
    fetchPasos(cadenaBusqueda) {
      this.loadingPasos = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      PasoProcesoCalidadService.getItems(this.proceso.id, cadenaBusqueda).then(
        (response) => {
          this.pasos = response.data;
          this.pasos = this.pasos.filter(x => x.id != this.paso.id)
          // this.items = this.items.map(function f(x) {
          //   return { ...x, layout_nombre: x.layout.nombre };
          // });
          this.loadingPasos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingPasos = false;
        }
      );
    },
    fetchCampos(cadenaBusqueda) {
      this.loadingCampos = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";

      CamposService.getItems(this.proceso.layout.id, cadenaBusqueda).then(
        (response) => {
          this.campos = response.data;
          this.loadingCampos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    fetchClasesPaso(cadenaBusqueda) {
      this.loadingClasesPaso = true;
      ListaService.getClasesPaso().then(
        (response) => {
          this.clasesPaso = response.data.data;
          this.loadingClasesPaso = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingClasesPaso = false;
        }
      );
    },
    fetchVariables(cadenaBusqueda) {
      this.loadingVariables = true;
      // VariableService.getItems(this.elemento.fuente_id, cadenaBusqueda).then(
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";

      VariableService.getItems(cadenaBusqueda).then(
        (response) => {
          this.variables = response.data.map(function f(x) {
            return {
              id: x.id,
              nombre: x.nombre,
              archivo: x.archivo,
              archivo_id: x.archivo_id,
              // layout_id: x.layout.id,
              // layout_nombre: x.layout.nombre,
            };
          });
          //   this.variables = response.data;
          this.loadingVariables = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingVariables = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/calidad/procesos-calidad/pasos" });
    },
  },
};
</script>

<style>
</style>
