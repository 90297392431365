import api from './api';

class ReglaService {
  
  getItems(cadenaBusqueda) {
    console.log(cadenaBusqueda)
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    console.log(`/api/reglas${cadenaBusqueda}`)
    return api.get(`/api/reglas${cadenaBusqueda}`);
  }

  getItem(id) {
    return api.get(`/api/reglas/${id}`);
  }

  newItem(params) {
    return api.post(`/api/reglas`, params)
  }

  editItem(params) {
    return api.put(`/api/reglas/${params.id}`, params)
  }
}

export default new ReglaService();