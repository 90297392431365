import api from './api';

class PasoProcesoCalidadService {

  getItems(id, cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/procesos_calidad/${id}/pasos_proceso_calidad${cadenaBusqueda}`);
  }

  newItem(id, params) {
    return api.post(`/api/procesos_calidad/${id}/pasos_proceso_calidad`, params)
  }

  editItem(id, params) {
    return api.put(`/api/procesos_calidad/${id}/pasos_proceso_calidad/${params.id}`, params)
  }

  deleteItem(procesoId, id) {
    return api.delete(`/api/procesos_calidad/${procesoId}/pasos_proceso_calidad/${id}`)
  }
}

export default new PasoProcesoCalidadService();
