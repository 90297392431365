import api from './api';

class ListaService {

  getItems(dominio, cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    console.log("---------")
    console.log(cadenaBusqueda)
    console.log(dominio)
    console.log("---------")
    return api.get(`/api/dominios/${dominio}/opciones${cadenaBusqueda}`);
  }

  getListItems(dominio, cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/dominios/${dominio}/listado/opciones${cadenaBusqueda}`);
  }

  newItem(dominio_id, params) {
    return api.post(`/api/dominios/${dominio_id}/opciones`, params)
  }

  editItem(dominio_id, params) {
    return api.put(`/api/dominios/${dominio_id}/opciones/${params.id}`, params)
  }


  // Listas por scope (Activo = true)
  getTransformaciones() {
    return api.get(`/api/transformaciones`);
  }

  getClasificaciones() {
    return api.get(`/api/clasificaciones`);
  }

  getTiposRegla() {
    return api.get(`/api/tipos-regla`);
  }

  getFunciones() {
    return api.get(`/api/funciones`);
  }

  getTiposOperacion() {
    return api.get(`/api/tipos_operacion`);
  }

  getPeriodicidades() {
    return api.get(`/api/periodicidades_ejecucion`);
  }

  getClasesPaso() {
    return api.get(`/api/clases_paso`);
  }

  getCategoriasConsultas() {
    return api.get(`/api/categorias_consulta`);
  }

  getLabelsPanoramicos() {
    return api.get(`/api/labels_panoramicos`);
  }

}

export default new ListaService();
