function validationField (type, field) {

    var validated = false;
  
    switch (type) {
      case 'email':
        validated = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(field);
        break;
      case 'short-string':
        validated = /^[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,50}$/.test(field);
        break;
      case 'short-string-alphanumeric':
        validated = /^[0-9A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,50}$/.test(field);
        break;
      case 'long-string':
        validated = /^[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,100}$/.test(field);
        break;
      case 'password':
        validated = /(?=^.{12,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(field);
        break;
      default:
        break;
    }
  
    return validated;
  }

  export default validationField;